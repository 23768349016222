/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
/**
 * Copyright 2022 Zapata Computing, Inc.
 */

import emotionStyled from '@emotion/styled';
import { useTheme as useThemeBasic } from '@emotion/react';
import createCache from '@emotion/cache';

export {
  css,
  jsx,
  ThemeProvider,
  CacheProvider as EmotionCacheProvider,
  withTheme,
} from '@emotion/react';
export { default as createEmotionCache } from '@emotion/cache';

declare module '@emotion/react' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface Theme extends SupersetTheme {}
}

export function useTheme() {
  const theme = useThemeBasic();
  // in the case there is no theme, useTheme returns an empty object
  if (Object.keys(theme).length === 0 && theme.constructor === Object) {
    throw new Error(
      'useTheme() could not find a ThemeContext. The <ThemeProvider/> component is likely missing from the app.',
    );
  }
  return theme;
}

export const emotionCache = createCache({
  key: 'superset',
});

export const styled = emotionStyled;

const defaultTheme = {
  borderRadius: 4,
  colors: {
    text: {
      label: '#879399',
      help: '#737373',
      primary: '#FFFFFFD8',
      contrast: '#000',
    },
    primary: {
      base: '#00B579',
      dark1: '#00B579',
      dark2: '#004229',
      light1: '#00EB80',
      light2: '#9EFFC2',
      light3: '#D2F4E0',
      light4: '#E9F9EF',
      light5: '#FFFFFF',
    },
    secondary: {
      base: '#FCCF2E',
      dark1: '#004229',
      dark2: '#FC8700',
      dark3: '#5C0038',
      light1: '#FDEEB5',
      light2: '#9EFFC2',
      light3: '#E9F9EF',
      light4: '#B8E4CE',
      light5: '#B8E4CE',
    },
    grayscale: {
      base: '#666666',
      dark1: '#323232',
      dark2: '#272727',
      dark3: '#000000',
      light1: '#B2B2B2',
      light2: '#E0E0E0',
      light3: '#F0F0F0',
      light4: '#2A2A2A',
      light5: '#2A2A2A',
    },
    error: {
      base: '#FF3863',
      dark1: '#781652',
      dark2: '#5C0038',
      light1: '#FF7A97',
      light2: '#FFBDCB',
    },
    warning: {
      base: '#FC8700',
      dark1: '#F27705',
      dark2: '#C56811',
      light1: '#FFA14A',
      light2: '#FFB977',
    },
    alert: {
      base: '#FCC700',
      dark1: '#BC9501',
      dark2: '#7D6300',
      light1: '#FDE380',
      light2: '#FEF9E6',
    },
    success: {
      base: '#00B578',
      dark1: '#0A8959',
      dark2: '#1E664A',
      light1: '#9EFFC2',
      light2: '#40F984',
    },
    info: {
      base: '#2BBDC7',
      dark1: '#1D9CA4',
      dark2: '#1A6771',
      light1: '#2FDED5',
      light2: '#34F6ED',
    },
    selected: '#B8E4CE10',
  },
  opacity: {
    light: '10%',
    mediumLight: '35%',
    mediumHeavy: '60%',
    heavy: '80%',
  },
  typography: {
    families: {
      sansSerif: `'Roobert', 'Inter', Helvetica, Arial`,
      serif: `Georgia, 'Times New Roman', Times, serif`,
      monospace: `'Fira Code', 'Courier New', monospace`,
    },
    weights: {
      light: 200,
      normal: 400,
      medium: 500,
      bold: 600,
    },
    sizes: {
      xxs: 9,
      xs: 10,
      s: 12,
      m: 14,
      l: 16,
      xl: 21,
      xxl: 28,
    },
  },
  zIndex: {
    aboveDashboardCharts: 10,
    dropdown: 11,
    max: 3000,
  },
  transitionTiming: 0.3,
  gridUnit: 4,
};

export type SupersetTheme = typeof defaultTheme;

export interface SupersetThemeProps {
  theme: SupersetTheme;
}

export const supersetTheme = defaultTheme;
